<template>
  <div class="p-2 mt-2">
    <div class="card">
      <h2>Dons</h2>
      <form class="app-search">
        <div class="form-group w-50 mb-2">
          <label for="per-page">Nombre par page:</label>
          <input
            type="number"
            id="per-page"
            class="form-control"
            step="5"
            min="10"
            max="100"
            v-model="perPage"
          />
        </div>
        <div class="app-search-box">
          <div class="input-group">
            <input
              type="search"
              v-model="search"
              class="form-control"
              placeholder="Rechercher ..."
            />
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>Utilisateur</th>
            <th>Email</th>
            <th>Montant</th>
            <th>Devise</th>
            <th>Methode</th>
            <th>Date</th>
            <th>Plus</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in paginate" :key="d.id">
            <td>
              {{
                (d.customerSurnamed == d.customerName) == ""
                  ? "Anonyme"
                  : d.customerSurname + " " + d.customerName
              }}
            </td>
            <td>{{ d.customerEmail }}</td>
            <td>{{ d.amount }}</td>
            <td>{{ d.currency }}</td>
            <td>{{ d.paymentMethod }}</td>
            <td>{{ dateFormat(d.paymentDate) }}</td>
            <td>
              <router-link
                class="btn btn-info btn-sm"
                :to="{ name: 'ShowDonation', params: { id: d.id } }"
                >Plus d'infos</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="perPage < searchResult.length"
        class="d-flex justify-content-around border mt-1"
      >
        <button
          :disabled="page === 1"
          class="btn btn-sm btn-info"
          @click="prev"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <p>page {{ page }}/{{ totalPages }}</p>
        <button
          :disabled="page == totalPages"
          class="btn btn-sm btn-info"
          @click="next"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      payments: [],
      perPage: 10,
      page: 1,
      search: "",
    };
  },
  methods: {
    async loadDonations() {
      this.$store
        .dispatch("loadDonations")
        .then((payments) => (this.payments = payments));
    },
    printLocation({ country, city }) {
      if (country === "" || country == null) return city;
      if (city === "" || city == null) return country;
      return city + ", " + country;
    },
    async toggle(e, field) {
      const data = { ...e, field: !e[field] };
      this.$http.put(`/auth/admin/teacher/${e.id}`, data).then(() => {
        this.loadDonations();
      });
    },
    dateFormat(date) {
      return moment(String(date)).format("MM/DD/YYYY hh:mm");
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.searchResult.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      return this.searchResult.slice(start, start + this.perPage);
    },
    searchResult() {
      if (!this.search) return this.payments;
      return this.payments.filter(
        (p) =>
          p.id == this.search ||
          p.teacher.firstName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          p.teacher.lastName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          p.teacher.phone.toLowerCase().includes(this.search.toLowerCase()) ||
          p.teacher.city.toLowerCase().includes(this.search.toLowerCase()) ||
          p.teacher.country.toLowerCase().includes(this.search.toLowerCase()) ||
          p.status.toLowerCase().includes(this.search.toLowerCase()) ||
          p.currency.toLowerCase().includes(this.search.toLowerCase()) ||
          p.teacher.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadDonations();
    });
  },
};
</script>
<style lang=""></style>
